<template>
  <div class="wallet-out">
    <el-form label-position="right" label-width="120px" size="medium">
      <el-form-item label="可提现金额：">
        <span class="balance">￥0.00</span>
      </el-form-item>

      <el-form-item label="提现到银行卡：">
        <el-select style="width: 30%" v-model="value" placeholder="请选择银行">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="提现金额：">
        <el-input style="width: 30%" placeholder="请输入提现金额">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>

      <el-form-item label="手续费：">
        <span>￥0.00</span>
      </el-form-item>

      <el-form-item label="实际扣款：">
        <span class="balance">￥0.00</span>
      </el-form-item>

      <el-form-item label="手机号：">
        <span>130****0000</span>
      </el-form-item>

      <el-form-item label="短信验证码：">
        <el-input style="width: 30%" placeholder="请输入短信验证码" />
      </el-form-item>

      <el-form-item label="交易密码：">
        <el-input
          v-model="pwd"
          style="width: 30%"
          type="password"
          placeholder="请输入交易密码"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary">确认提现</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pwd: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.wallet-out {
  .balance {
    color: #e62d31;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>