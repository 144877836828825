<template>
  <div class="wallet-topup">
    <panel-title icon="el-icon-coin" title="充值提现" />

    <panel-content>
      <div slot="filter" class="filter">
        <span :class="{ active: showType === 'in' }" @click="showType = 'in'"
          >账户充值</span
        >
        <span :class="{ active: showType === 'out' }" @click="showType = 'out'"
          >账户提现</span
        >
      </div>

      <div slot="content">
        <wallet-in v-show="showType === 'in'" />

        <wallet-out v-show="showType === 'out'" />
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
import WalletIn from "./WalletIn.vue";
import WalletOut from "./WalletOut.vue";
export default {
  components: { PanelTitle, PanelContent, WalletIn, WalletOut },

  computed: {
    showType: {
      get() {
        return this.$route.query.type || "in";
      },

      set(v) {
        this.$router.push(this.$route.path + `?type=${v}`);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-topup {
  .filter {
    span {
      cursor: pointer;
      display: inline-block;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #ccc;

      font-size: 14px;

      line-height: 17px;

      &:hover,
      &:active,
      &.active {
        color: red;
      }
    }

    span:last-child {
      border-right: none;
    }
  }
}
</style>