<template>
  <div class="wallet-in">
    <el-form label-position="right" label-width="100px" size="medium">
      <el-form-item label="充值类型：">
        <el-radio-group v-model="topupType">
          <el-radio label="0">在线充值</el-radio>
          <el-radio label="1">线下充值</el-radio>
        </el-radio-group>
      </el-form-item>

      <template v-if="topupType === '0'">
        <el-form-item label="账户余额：">
          <span class="balance">￥0.00</span>
        </el-form-item>

        <el-form-item label="充值金额：">
          <el-input style="width: 30%" placeholder="请输入充值金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="充值方式：">
          <el-radio-group v-model="ptType">
            <el-radio label="0">
              <img
                :src="require('@/assets/images/ori/ali-pay.jpg')"
                alt=""
                srcset=""
              />
            </el-radio>
            <el-radio label="1">
              <img
                :src="require('@/assets/images/ori/wx-pay.jpg')"
                alt=""
                srcset=""
              />
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary">确认充值</el-button>
        </el-form-item>
      </template>

      <template v-if="topupType === '1'">
        <el-form-item label="充值银行：">
          <el-select
            style="width: 30%"
            v-model="value"
            placeholder="请选择银行"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="充值金额：">
          <el-input style="width: 30%" placeholder="请输入充值金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="充值时间：">
          <el-date-picker
            style="width: 30%"
            v-model="value1"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="附言：">
          <el-input
            style="width: 30%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="请输入内容"
            v-model="textarea2"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="支付凭据：">
          <el-link type="primary">+上传凭证</el-link>
        </el-form-item>

        <el-form-item>
          <el-button type="primary">确认</el-button>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 在线充值0、线下充值1
      topupType: "0",

      // 充值平台
      ptType: "0",

      textarea2: "",

      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style>
</style>